import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button,
  Typography,
  Box,
  Divider,
  Link,
  useTheme,
  useMediaQuery,
  IconButton,
  Chip,
  SelectChangeEvent,
  Snackbar,
  Alert,
  TextField
} from '@mui/material';
import { Booking, getPaymentStatusText, Office, PaymentMethod, PaymentStatus } from 'shared-lib';
import CloseIcon from '@mui/icons-material/Close';
import { getStatusText, getStatusColor } from 'shared-lib';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LockIcon from '@mui/icons-material/Lock';
import { AdminBookingService } from '../services/AdminBookingService';
import { OfficeService } from 'shared-lib';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import TimerIcon from '@mui/icons-material/Timer';

interface BookingDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  booking: Booking;
  office: Office;
}

const BookingDetailsDialog: React.FC<BookingDetailsDialogProps> = ({
  open,
  onClose,
  booking,
  office,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const adminBookingService = AdminBookingService.getInstance();
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentBooking, setCurrentBooking] = useState<Booking>(booking);
  const officeService = OfficeService.getInstance();
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [cancelComment, setCancelComment] = useState('');

  useEffect(() => {
    // Подписываемся на изменения конкретного бронирования
    const unsubscribe = officeService.subscribeToBooking(booking.id, (updatedBooking) => {
      if (updatedBooking) {
        setCurrentBooking(updatedBooking);
      }
    });

    return () => unsubscribe();
  }, [booking.id, officeService]);

  const handlePaymentMethodChange = async (event: SelectChangeEvent) => {
    const newMethod = event.target.value;
    setIsProcessing(true);
    try {
      await adminBookingService.updatePaymentMethod(booking.id, newMethod);
    } catch (error) {
      console.error('Error updating payment method:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const formatDate = (date: Date, timezone: string) => {
    return new Date(date).toLocaleDateString('ru-RU', {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: timezone
    });
  };

  const formatTimeRange = (startDate: Date, endDate: Date, timezone: string) => {
    const options = { hour: '2-digit' as const, minute: '2-digit' as const, timeZone: timezone };
    return `${new Date(startDate).toLocaleTimeString('ru-RU', options)} - ${new Date(endDate).toLocaleTimeString('ru-RU', options)}`;
  };

  const formatPhone = (phone: string) => {
    return (
      <Link href={`tel:${phone}`} color="primary">
        {phone}
      </Link>
    );
  };

  const formatEmail = (email: string) => {
    return (
      <Link href={`mailto:${email}`} color="primary">
        {email}
      </Link>
    );
  };

  const formatPassStatus = (status?: string) => {
    switch (status) {
      case 'ACTIVE':
        return 'Активен';
      case 'PENDING':
        return 'Ожидает активации';
      case 'FAILED':
        return 'Ошибка';
      default:
        return 'Неизвестно';
    }
  };

  const handleConfirmCashPayment = async () => {
    setIsProcessing(true);
    try {
      await adminBookingService.confirmCashPayment(booking.id);
    } catch (error) {
      console.error('Error confirming cash payment:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSendClosingReceipt = async () => {
    setIsProcessing(true);
    setError(null);
    setSuccessMessage(null);
    try {
      await adminBookingService.sendClosingReceipt(booking.id);
      setSuccessMessage('Закрывающий чек успешно отправлен');
    } catch (error) {
      console.error('Error sending closing receipt:', error);
      const errorMessage = error instanceof Error ? error.message : 'Неизвестная ошибка';
      setError(errorMessage);
    } finally {
      setIsProcessing(false);
    }
  };

  const shouldShowClosingReceiptButton = 
    currentBooking.payment?.status === PaymentStatus.CONFIRMED && 
    !currentBooking.payment.receiptSent &&
    currentBooking.payment.method === 'ONLINE';

  const receiptSentDate = currentBooking.payment?.receiptSentAt 
    ? format(new Date(currentBooking.payment.receiptSentAt), 'd MMMM yyyy в HH:mm', { locale: ru })
    : null;

  const handleCancel = async () => {
    if (!cancelComment.trim()) {
      setError('Пожалуйста, укажите причину отмены');
      return;
    }

    setIsProcessing(true);
    try {
      await adminBookingService.cancelBooking(booking.id, cancelComment);
      setSuccessMessage('Бронирование успешно отменено');
      setOpenCancelDialog(false);
      setCancelComment('');
    } catch (error) {
      console.error('Error cancelling booking:', error);
      setError('Ошибка при отмене бронирования');
    } finally {
      setIsProcessing(false);
    }
  };

  const isBookingEnded = () => {
    const now = new Date();
    const endTime = new Date(currentBooking.endTime);
    return endTime < now;
  };

  const formatPaymentTimeLeft = (createdAt: string): string => {
    const deadline = new Date(new Date(createdAt).getTime() + 60 * 60 * 1000);
    const now = new Date();
    const diffMs = deadline.getTime() - now.getTime();
    const diffMinutes = Math.ceil(diffMs / (1000 * 60));
    
    if (diffMinutes <= 0) return '';
    
    const minutes = diffMinutes % 60;
    const hours = Math.floor(diffMinutes / 60);
    
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}`;
    }
    
    return `${minutes} мин`;
  };

  const isPaymentExpired = (createdAt: Date): boolean => {
    const now = new Date();
    const deadline = new Date(new Date(createdAt).getTime() + 60 * 60 * 1000);
    return now > deadline;
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose} 
        maxWidth="sm" 
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography variant="h6">Детали бронирования</Typography>
            <Typography variant="caption" color="text.secondary">
              ID: {booking.id}
            </Typography>
          </Box>
          <IconButton edge="end" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              {office.name}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <EventIcon sx={{ mr: 1, color: 'text.secondary' }} />
              <Typography>
                {formatDate(currentBooking.startTime, currentBooking.timezone)}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AccessTimeIcon sx={{ mr: 1, color: 'text.secondary' }} />
              <Typography>
                {formatTimeRange(currentBooking.startTime, currentBooking.endTime, currentBooking.timezone)}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Клиент
            </Typography>
            <Typography>
              {currentBooking.customer_first_name} {currentBooking.customer_last_name}
            </Typography>
            <Typography>
              Телефон: {formatPhone(currentBooking.customer_phone)}
            </Typography>
            {currentBooking.customer_email && (
              <Typography>
                Email: {formatEmail(currentBooking.customer_email)}
              </Typography>
            )}
          </Box>

          {currentBooking.booking_comment && (
            <>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Комментарий
                </Typography>
                <Typography>
                  {currentBooking.booking_comment}
                </Typography>
              </Box>
            </>
          )}

          <Divider sx={{ my: 2 }} />

          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Информация о бронировании
            </Typography>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Typography component="span">
                Статус:
              </Typography>           
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Chip 
                  label={getStatusText(currentBooking.status)}
                  color={getStatusColor(currentBooking.status)}
                  size="small"
                />
              </Box>
            </Box>
            
            {currentBooking.payment?.method === PaymentMethod.FREE ? (
              <Typography component="div" gutterBottom>
                Стоимость: Бесплатно
              </Typography>
            ) : (
              <>
                <Typography component="div" gutterBottom>
                  Стоимость: {currentBooking.price / 100} ₽
                </Typography>
                
                {currentBooking.totalPrice !== currentBooking.price && (
                  <Typography component="div" gutterBottom>
                    Итоговая стоимость: {currentBooking.totalPrice / 100} ₽
                  </Typography>
                )}
              </>
            )}

            <Box sx={{ mt: 2, mb: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <Typography component="div">
                  Статус оплаты: {
                    currentBooking.payment?.method === PaymentMethod.FREE ? 
                      'Бесплатное бронирование' :
                    currentBooking.payment?.status ? 
                      getPaymentStatusText(currentBooking.payment.status) : 
                      'Не оплачено'
                  }
                </Typography>
                {currentBooking.payment?.status === 'PENDING' && 
                  currentBooking.payment.method !== PaymentMethod.FREE &&
                  currentBooking.status !== 'CANCELLED' &&
                  (
                    <>
                      {isPaymentExpired(new Date(currentBooking.createdAt)) ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', color: 'error.main' }}>
                          <TimerIcon sx={{ mr: 0.5 }} />
                          <Typography variant="body2" color="error.main">
                            Время на оплату истекло
                          </Typography>
                        </Box>
                      ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center', color: 'warning.main' }}>
                          <TimerIcon sx={{ mr: 0.5 }} />
                          <Typography variant="body2" color="warning.main" data-testid="payment-timer">
                            Осталось: {formatPaymentTimeLeft(currentBooking.createdAt)}
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
              </Box>

              {(!currentBooking.payment?.status || currentBooking.payment.status === PaymentStatus.PENDING) && (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmCashPayment}
                    disabled={isProcessing}
                    size="small"
                  >
                    {isProcessing ? 'Подтверждение...' : 'Подтвердить оплату'}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handlePaymentMethodChange({ target: { value: PaymentMethod.FREE }} as SelectChangeEvent)}
                    disabled={isProcessing}
                    size="small"
                  >
                    Сделать бесплатным
                  </Button>
                </Box>
              )}
            </Box>

            {currentBooking.payment?.status === PaymentStatus.CONFIRMED && (
              <Box sx={{ mt: 1 }}>
                {shouldShowClosingReceiptButton ? (
                  <Button
                    variant="outlined"
                    onClick={handleSendClosingReceipt}
                    disabled={isProcessing}
                    size="small"
                  >
                    {isProcessing ? 'Отправка...' : 'Отправить закрывающий чек'}
                  </Button>
                ) : receiptSentDate && (
                  <Typography variant="body2" color="text.secondary">
                    Закрывающий чек отправлен {receiptSentDate}
                  </Typography>
                )}
              </Box>
            )}
          </Box>

          {currentBooking.passes && Object.keys(currentBooking.passes).length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" gutterBottom>
                  Коды доступа
                </Typography>
                {Object.entries(currentBooking.passes).map(([deviceId, pass]) => (
                  <Box 
                    key={deviceId}
                    sx={{ 
                      display: 'flex',
                      alignItems: 'flex-start',
                      mb: 1,
                      gap: 1
                    }}
                  >
                    <LockIcon sx={{ color: 'text.secondary' }} />
                    <Box>
                      <Typography variant="subtitle2">
                        {pass.name}
                      </Typography>
                      {pass.password && (
                        <Typography variant="body2">
                          Код: {pass.password}
                        </Typography>
                      )}
                      <Typography variant="body2" color="text.secondary">
                        Статус: {formatPassStatus(pass.status)}
                      </Typography>
                      {pass.instructions && (
                        <Typography variant="body2" color="text.secondary">
                          {pass.instructions}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpenCancelDialog(true)} 
            color="error"
            disabled={currentBooking.status === 'CANCELLED' || isBookingEnded()}
          >
            Отменить бронирование
          </Button>
          <Button onClick={onClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
      
      {/* Cancel Dialog */}
      <Dialog open={openCancelDialog} onClose={() => setOpenCancelDialog(false)}>
        <DialogTitle>Отмена бронирования</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Вы уверены, что хотите отменить это бронирование?
          </Typography>
          {isBookingEnded() && (
            <Typography color="error" gutterBottom>
              Бронирование уже завершено и не может быть отменено
            </Typography>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Причина отмены"
            fullWidth
            multiline
            rows={3}
            value={cancelComment}
            onChange={(e) => setCancelComment(e.target.value)}
            error={!cancelComment.trim() && !!error}
            helperText={!cancelComment.trim() && error ? error : ''}
            disabled={isBookingEnded()}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setOpenCancelDialog(false);
              setCancelComment('');
              setError(null);
            }}
            disabled={isProcessing}
          >
            Отмена
          </Button>
          <Button 
            onClick={handleCancel} 
            color="error"
            disabled={isProcessing || isBookingEnded()}
          >
            {isProcessing ? 'Отмена...' : 'Подтвердить отмену'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar 
        open={!!successMessage} 
        autoHideDuration={6000} 
        onClose={() => setSuccessMessage(null)}
      >
        <Alert severity="success" onClose={() => setSuccessMessage(null)}>
          {successMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BookingDetailsDialog; 