export const getStatusText = (status) => {
    switch (status) {
        case 'NEW':
            return 'Подтверждено';
        case 'DRAFT':
            return 'Черновик';
        case 'CANCELLED':
            return 'Отменено';
        case 'IN_PROGRESS':
            return 'В процессе';
        case 'COMPLETED':
            return 'Завершено';
        default:
            return status;
    }
};
export const getStatusColor = (status) => {
    switch (status) {
        case 'NEW':
            return 'success';
        case 'DRAFT':
            return 'warning';
        case 'CANCELLED':
            return 'error';
        case 'IN_PROGRESS':
            return 'info';
        case 'COMPLETED':
            return 'success';
        default:
            return 'default';
    }
};
export const PaymentStatus = {
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    CANCELLED: 'CANCELLED',
    AUTHORIZED: 'AUTHORIZED',
    REJECTED: 'REJECTED',
    REFUNDED: 'REFUNDED'
};
export const getPaymentStatusText = (status) => {
    switch (status) {
        case PaymentStatus.CONFIRMED:
            return 'Оплачено';
        case PaymentStatus.AUTHORIZED:
            return 'Деньги зарезервированы';
        case PaymentStatus.PENDING:
            return 'Ожидает оплаты';
        case PaymentStatus.CANCELLED:
            return 'Платеж отменен';
        case PaymentStatus.REJECTED:
            return 'Платеж отклонен';
        case PaymentStatus.REFUNDED:
            return 'Платеж возвращен';
        default:
            return status;
    }
};
