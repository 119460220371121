import { useQuery, useQueryClient } from '@tanstack/react-query';
import { OfficeService, Booking } from 'shared-lib';
import { useEffect, useRef, useState } from 'react';

const officeService = OfficeService.getInstance();

export const useOfficeBookings = (
  officeIds: string[], 
  startDate: Date, 
  endDate: Date
) => {
  const queryClient = useQueryClient();
  const currentDataRef = useRef<Booking[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // Настраиваем подписки на Firebase
  useEffect(() => {
    // Проверяем наличие данных в кеше для всех запрошенных офисов
    const hasAllCachedData = officeIds.every(officeId => {
      const officeQueryKey = ['officeBookings', officeId, startDate.toISOString(), endDate.toISOString()];
      return queryClient.getQueryData(officeQueryKey) !== undefined;
    });

    // Начинаем загрузку только если нет кешированных данных
    if (!hasAllCachedData) {
      currentDataRef.current = [];
      setIsLoading(true);
    }

    const unsubscribes = officeIds.map(officeId => {
      const officeQueryKey = ['officeBookings', officeId, startDate.toISOString(), endDate.toISOString()];
      
      return officeService.subscribeToOfficeBookings(
        officeId,
        startDate,
        endDate,
        (bookings) => {
          // Обновляем данные для конкретного офиса
          queryClient.setQueryData<Booking[]>(
            officeQueryKey,
            bookings
          );

          // Обновляем общие данные
          const allOfficesData = officeIds.map(id => 
            queryClient.getQueryData<Booking[]>(
              ['officeBookings', id, startDate.toISOString(), endDate.toISOString()]
            ) || []
          ).flat();

          currentDataRef.current = allOfficesData;
          queryClient.setQueryData(
            ['adminBookings', officeIds, startDate.toISOString(), endDate.toISOString()],
            allOfficesData
          );
          setIsLoading(false);
        }
      );
    });

    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe());
    };
  }, [officeIds, startDate, endDate, queryClient]);

  const query = useQuery<Booking[]>({
    queryKey: ['adminBookings', officeIds, startDate.toISOString(), endDate.toISOString()],
    queryFn: async () => {
      const bookingsArrays = await Promise.all(
        officeIds.map(officeId => 
          new Promise<Booking[]>((resolve) => {
            const unsubscribe = officeService.subscribeToOfficeBookings(
              officeId,
              startDate,
              endDate,
              (bookings) => {
                resolve(bookings);
                unsubscribe();
              }
            );
          })
        )
      );
      
      const result = bookingsArrays.flat();
      currentDataRef.current = result;
      setIsLoading(false);
      return result;
    },
    initialData: currentDataRef.current,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity
  });

  return {
    ...query,
    isLoading
  };
}; 